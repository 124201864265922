import React from 'react';
import { Trans } from 'react-i18next';
import styled from 'styled-components';
import { useGetOfficeData } from 'javascript/features/realWorld/hooks/useGetOfficeData';

interface Props {
  theme: string | undefined;
}

export const SelectThemeArea: React.FC<Props> = ({ theme }) => {
  const { changeTheme } = useGetOfficeData();

  const THEME_LIST = {
    // SINMPLEを削除した影響で、2からスタートしている
    AQUA: 2,
    SKY: 3,
    UNIVERSE: 4,
    UNDERGROUND: 5,
    TREE: 6,
    SWEET: 7,
    NEON: 8,
    FLOWER: 9,
    CHINESE: 10,
    DESERT: 11,
    RESORT: 12,
    FESTIVAL: 13,
    HAWAII: 14,
    GHOST: 15,
    EUROPEAN: 16,
    HALLOWEEN: 17,
    JAPANESE_GARDEN: 18,
    CHRISTMAS: 19,
    NEWYEAR: 20,
    CHOCO: 21,
  };

  // 画像に含まれる文字列から、現在どのテーマが選択されているか判定し、BEで管理しているテーマ毎の数値を返す
  const evaluateSelectedTheme = (theme: string | undefined | null) => {
    if (theme === null || theme === undefined) return;
    if (theme.includes('under_water')) return THEME_LIST.AQUA;
    if (theme.includes('sky')) return THEME_LIST.SKY;
    if (theme.includes('universe')) return THEME_LIST.UNIVERSE;
    if (theme.includes('under_ground')) return THEME_LIST.UNDERGROUND;
    if (theme.includes('tree')) return THEME_LIST.TREE;
    if (theme.includes('sweet')) return THEME_LIST.SWEET;
    if (theme.includes('neon')) return THEME_LIST.NEON;
    if (theme.includes('flower')) return THEME_LIST.FLOWER;
    if (theme.includes('chinese')) return THEME_LIST.CHINESE;
    if (theme.includes('desert')) return THEME_LIST.DESERT;
    if (theme.includes('resort')) return THEME_LIST.RESORT;
    if (theme.includes('festival')) return THEME_LIST.FESTIVAL;
    if (theme.includes('hawaii')) return THEME_LIST.HAWAII;
    if (theme.includes('ghost')) return THEME_LIST.GHOST;
    if (theme.includes('ghost')) return THEME_LIST.GHOST;
    if (theme.includes('european')) return THEME_LIST.EUROPEAN;
    if (theme.includes('halloween')) return THEME_LIST.HALLOWEEN;
    if (theme.includes('japanese-garden')) return THEME_LIST.JAPANESE_GARDEN;
    if (theme.includes('christmas')) return THEME_LIST.CHRISTMAS;
    if (theme.includes('newyear')) return THEME_LIST.NEWYEAR;
    if (theme.includes('choco')) return THEME_LIST.CHOCO;
    return;
  };
  const themeNum = evaluateSelectedTheme(theme);
  return (
    <SelectThemeWrapper>
      {/* 12月 */}
      <Theme
        onClick={(e) => changeTheme(THEME_LIST.CHRISTMAS, e)}
        isSelected={themeNum === THEME_LIST.CHRISTMAS}
      >
        <Trans i18nKey='GenelalThemeChristmas' />
      </Theme>
      {/* 11月 */}
      <Theme
        onClick={(e) => changeTheme(THEME_LIST.JAPANESE_GARDEN, e)}
        isSelected={themeNum === THEME_LIST.JAPANESE_GARDEN}
      >
        <Trans i18nKey='GenelalThemeJapanesegarden' />
      </Theme>
      {/* 10月 */}
      <Theme
        onClick={(e) => changeTheme(THEME_LIST.HALLOWEEN, e)}
        isSelected={themeNum === THEME_LIST.HALLOWEEN}
      >
        <Trans i18nKey='GenelalThemeHalloween' />
      </Theme>
      {/* 8月 */}
      <Theme
        onClick={(e) => changeTheme(THEME_LIST.FESTIVAL, e)}
        isSelected={themeNum === THEME_LIST.FESTIVAL}
      >
        <Trans i18nKey='GenelalThemeFestival' />
      </Theme>
      {/* 7月 */}
      <Theme
        onClick={(e) => changeTheme(THEME_LIST.HAWAII, e)}
        isSelected={themeNum === THEME_LIST.HAWAII}
      >
        <Trans i18nKey='GenelalThemeHawaii' />
      </Theme>
      {/* 6月 */}
      <Theme
        onClick={(e) => changeTheme(THEME_LIST.NEON, e)}
        isSelected={themeNum === THEME_LIST.NEON}
      >
        <Trans i18nKey='GenelalThemeNeon' />
      </Theme>
      {/* 5月 */}
      <Theme
        onClick={(e) => changeTheme(THEME_LIST.RESORT, e)}
        isSelected={themeNum === THEME_LIST.RESORT}
      >
        <Trans i18nKey='GenelalThemeResort' />
      </Theme>
      {/* 4月 */}
      <Theme
        onClick={(e) => changeTheme(THEME_LIST.EUROPEAN, e)}
        isSelected={themeNum === THEME_LIST.EUROPEAN}
      >
        <Trans i18nKey='GenelalThemeEuropean' />
      </Theme>
      {/* 3月 */}
      <Theme
        onClick={(e) => changeTheme(THEME_LIST.FLOWER, e)}
        isSelected={themeNum === THEME_LIST.FLOWER}
      >
        <Trans i18nKey='GenelalThemeFlower' />
      </Theme>
      {/* 2月 */}
      <Theme
        onClick={(e) => changeTheme(THEME_LIST.CHOCO, e)}
        isSelected={themeNum === THEME_LIST.CHOCO}
      >
        <Trans i18nKey='GenelalThemeChoco' />
      </Theme>
      {/* 1月 */}
      <Theme
        onClick={(e) => changeTheme(THEME_LIST.NEWYEAR, e)}
        isSelected={themeNum === THEME_LIST.NEWYEAR}
      >
        <Trans i18nKey='GenelalThemeNewyaer' />
      </Theme>
      <Theme
        onClick={(e) => changeTheme(THEME_LIST.SWEET, e)}
        isSelected={themeNum === THEME_LIST.SWEET}
      >
        <Trans i18nKey='GenelalThemeSweet' />
      </Theme>
      <Theme
        onClick={(e) => changeTheme(THEME_LIST.GHOST, e)}
        isSelected={themeNum === THEME_LIST.GHOST}
      >
        <Trans i18nKey='GenelalThemeGhost' />
      </Theme>
      <Theme
        onClick={(e) => changeTheme(THEME_LIST.DESERT, e)}
        isSelected={themeNum === THEME_LIST.DESERT}
      >
        <Trans i18nKey='GenelalThemeDesert' />
      </Theme>
      <Theme
        onClick={(e) => changeTheme(THEME_LIST.CHINESE, e)}
        isSelected={themeNum === THEME_LIST.CHINESE}
      >
        <Trans i18nKey='GenelalThemeChinese' />
      </Theme>
      <Theme
        onClick={(e) => changeTheme(THEME_LIST.TREE, e)}
        isSelected={themeNum === THEME_LIST.TREE}
      >
        <Trans i18nKey='GenelalThemeTree' />
      </Theme>
      <Theme
        onClick={(e) => changeTheme(THEME_LIST.AQUA, e)}
        isSelected={themeNum === THEME_LIST.AQUA}
      >
        <Trans i18nKey='GenelalThemeUnderwater' />
      </Theme>
      <Theme
        onClick={(e) => changeTheme(THEME_LIST.UNIVERSE, e)}
        isSelected={themeNum === THEME_LIST.UNIVERSE}
      >
        <Trans i18nKey='GenelalThemeUniverse' />
      </Theme>
      <Theme
        onClick={(e) => changeTheme(THEME_LIST.UNDERGROUND, e)}
        isSelected={themeNum === THEME_LIST.UNDERGROUND}
      >
        <Trans i18nKey='GenelalThemeUnderground' />
      </Theme>
      <Theme
        onClick={(e) => changeTheme(THEME_LIST.SKY, e)}
        isSelected={themeNum === THEME_LIST.SKY}
      >
        <Trans i18nKey='GenelalThemeSky' />
      </Theme>
    </SelectThemeWrapper>
  );
};

const SelectThemeWrapper = styled.div`
  display: flex;
  margin-left: 24px;
  max-width: 600px;
  gap: 16px;
  white-space: nowrap;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

// const StyledNewLabel = styled.span`
//   position: absolute;
//   top: -5px;
//   right: -8px;
//   color: #f00;
//   font-size: 10px;
//   transform: scale(0.7);
// `;

const Theme = styled.p<{ isSelected: boolean }>`
  position: relative;
  padding-top: 4px;
  color: ${({ isSelected }) => (isSelected ? '#00a0e9' : '#999')};
  font-size: 12px;
  cursor: pointer;
`;
