// 毎月追加されるアバターパーツのうち、どこからが新しく追加されたパーツかを判断する数値(product_number)を管理する定数

// 目
export const newEyeIndex = 196;
// 口
export const newMouthIndex = 175;
// 服
export const newClothIndex = 216;
// 髪
export const newHairIndex = 181;
// 帽子
export const newHatIndex = 192;
